import * as yup from 'yup';

import { ADD_NEW } from '@sympli/ui-framework/components/formik/address-field/values';

import { isAusMobileNumber } from 'src/containers/onboarding/helper';
import { PersonModel } from 'src/models';
import { nameValidationRule } from 'src/utils/validationRules';

export const yupPersonObject = {
  firstName: nameValidationRule('name'),
  middleName: nameValidationRule('name', false),
  lastName: nameValidationRule('name'),
  jobTitle: yup.string().required('Please select job title'),
  email: yup //
    .string()
    .email('Please enter correct email address')
    .required('Required'),
  mobileNumber: yup //
    .string()
    .required('Required')
    .test('Australian phone number test', 'Please enter correct mobile number', isAusMobileNumber)
};

export const yupNewPerson = yup.object({
  existingOrNew: yup.string().required('Please select from existing or add new person'),
  ...yupPersonObject
});

export const yupOldPerson = yup.object({
  existingOrNew: yup.string().required('Please select from existing or add new person')
});

export const yupPerson = yup.lazy((person: PersonModel) => {
  if (person.existingOrNew === ADD_NEW) {
    return yupNewPerson;
  }
  return yupOldPerson;
});
// TODO phone number validation, email validation fix
export const yupPersonList = yup //
  .array()
  .of(yupPerson)
  .min(1);

export const yupBankAccount = yup.object({
  bsbNumber: yup //
    .string()
    .trim()
    .required('Required'),
  accountNumber: yup //
    .string()
    .trim()
    .required('Required')
    .max(9, 'Account Number cannot be more than 9 characters'),
  bankName: yup //
    .string()
    .trim()
    .required('Required'),
  accountName: yup //
    .string()
    .trim()
    .required('Required')
});
