import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';
import { REGEX_CHARACTER } from 'src/utils/regex';

export const nameValidationRule = (fieldName: string, required: boolean = true, length: number = 40) => {
  let rule = !required ? yup.string() : yup.string().required(msg.REQUIRED);
  return rule
    .max(length, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(length))
    .matches(REGEX_CHARACTER, msg.CAN_ONLY_CONTAIN_LETTERS_DASHES_APOSTROPHES_AND_SPACES(fieldName));
};
